<template>
  <div>
    <b-table
      id="EntityChangetrackingTable"
      :busy="is.initial"
      :fields="entitiesTable.fields"
      :items="entitiesTable.items"
      striped
      hover
      show-empty
      sort-icon-left
    >
      <template #table-busy>
        <div class="text-center">
          <loader inline/>
          <div>{{ $t('components.table.busy') }}</div>
        </div>
      </template>

      <template #cell($id)="data">
        {{ data.item.id }}
      </template>

      <template #cell(changeType)="data">
        <state-badge
          :states="table.options.items.changeType.STATES"
          :data="data.value"
          :calculation="table.options.items.changeType.calculation"
        />
      </template>

      <template #cell($value)="data">
        <template v-if="data.item.oldIsObjectValue || data.item.newIsObjectValue"><b-badge variant="dark">{{ data.item.entityName }}</b-badge></template>
        <template v-else><b-badge variant="dark">{{ data.item.entityName }}</b-badge> <b-badge variant="light">{{ data.item.propertyName }}</b-badge></template>
      </template>

      <template #cell(oldValue)="data">
        <template v-if="data.item.oldIsObjectValue">"
          <b-icon :id="`OldValue_${data.index}_InfoIcon`" icon="circle-info" variant="info"/>

          <b-popover :target="`OldValue_${data.index}_InfoIcon`" triggers="hover">
            <template #title>{{ data.item.entityName }}</template>

            <ul class="list-unstyled mb-0">
              <li v-for="(value, key) in data.value" :key="key"><strong>{{ key }}</strong> {{ value }}</li>
            </ul>
          </b-popover>
        </template>

        <template v-else>{{ data.value }}</template>
      </template>

      <template #cell(newValue)="data">
        <template v-if="data.item.newIsObjectValue">
          <b-icon :id="`NewValue_${data.index}_InfoIcon`" icon="circle-info" variant="info"/>

          <b-popover :target="`NewValue_${data.index}_InfoIcon`" triggers="hover">
            <template #title>{{ data.item.entityName }}</template>

            <ul class="list-unstyled mb-0">
              <li v-for="(value, key) in data.value" :key="key"><strong>{{ key }}</strong> {{ value }}</li>
            </ul>
          </b-popover>
        </template>

        <template v-else>{{ data.value }}</template>
      </template>

      <template #cell(created)="data">
        <span v-b-tooltip.hover.left="$d(new Date(data.value), 'long')"><timeago :datetime="data.value" auto-update/></span>
      </template>
    </b-table>

    <b-pagination
      v-model="statistics.page.number"
      :per-page="statistics.page.size"
      :total-rows="statistics.total.items"
      :disabled="statistics.total.pages <= 1"
      align="center"
      aria-controls="EntityChangetrackingTable"
      @change="loadPage"
    />
  </div>
</template>

<script>
import { CHANGETRACKING_STATES, STOREMODULE_ENDPOINTMODULE_MAP, CHANGETRACKING_STATEBADGE_VARIANTS } from '@/constants'

import Loader from '@/components/Loader'
import StateBadge from '@/components/StateBadge'
import Table from '@/assets/js/helper/table'

const CHANGETRACKING_STATE_KEYS = Object.keys(CHANGETRACKING_STATES)

export default {
  name: 'EntityChangetrackingTable',
  components: {
    Loader,
    StateBadge
  },
  props: {
    entityBy: {
      type: Object,
      default: () => ({ name: null, id: null })
    },
    storeModule: {
      type: String,
      default: () => STOREMODULE_ENDPOINTMODULE_MAP.employee // defaults to the customer store module, as this was used before storeModule was introduced.
    }
  },
  data () {
    return {
      table: {
        options: {
          fields: {
            includes: ['$id', '$value', 'oldValue'],
            excludes: ['loginId', 'oldIsObjectValue', 'newIsObjectValue'],
            sorting: ['$id', 'userName', 'changeType', '$value', 'oldValue', 'newValue', 'created'],
            sortable: [],
            labelKey: 'components.entity.changetrackingtable.label.{field}',
            label: {},
            variant: {},
            class: {
              $id: 'cell-min cell-align-center',
              changeType: 'cell-min cell-align-center',
              created: 'cell-align-right'
            },
            thClass: {
              oldValue: 'cell-nowrap',
              newValue: 'cell-nowrap'
            },
            tdClass: {
              oldValue: 'cell-pre',
              newValue: 'cell-pre'
            }
          },
          items: {
            changeType: {
              STATES: CHANGETRACKING_STATE_KEYS.reduce((states, sKey) => Object.assign(states, { [sKey]: { variant: CHANGETRACKING_STATEBADGE_VARIANTS[sKey], text: CHANGETRACKING_STATES[sKey] } }), {}),
              calculation: data => CHANGETRACKING_STATE_KEYS.find(sKey => CHANGETRACKING_STATES[sKey] === data)
            }
          }
        }
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters['changetracking/is']
    },
    statistics () {
      return this.$store.getters['changetracking/statistics']
    },
    entities () {
      return this.$store.getters['changetracking/get']
        .map(entity => Object.assign({}, entity, {
          oldIsObjectValue: typeof entity.oldValue === 'object',
          newIsObjectValue: typeof entity.newValue === 'object'
        }))
    },
    entitiesTable () {
      return new Table(this.entities, this.table.options.fields)
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch('changetracking/get', { storeModule: this.storeModule, by: this.entityBy, data: this.pagination })
    },
    loadPage (number) {
      this.$store.dispatch('changetracking/getPage', { storeModule: this.storeModule, by: this.entityBy, number })
    }
  },
  created () {
    this.getEntities()
  },
  watch: {
    entityBy: {
      deep: true,
      handler () {
        this.getEntitites()
      }
    },
    pagination: {
      deep: true,
      handler () {
        this.getEntities()
      }
    }
  }
}
</script>

<style lang="scss"></style>
